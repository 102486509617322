@font-face {
  font-family: 'Avenir Next';
  src: url('./assets/fonts/AvenirNext-Medium.woff2') format('woff2'),
    url('./assets/fonts/AvenirNext-Medium.woff') format('woff'),
    url('./assets/fonts/AvenirNext-Medium.ttf') format('truetype');
  font-weight: normals;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./assets/fonts/AvenirNext-DemiBold.woff2') format('woff2'),
    url('./assets/fonts/AvenirNext-DemiBold.woff') format('woff'),
    url('./assets/fonts/AvenirNext-DemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html,
body {
  padding: 0px;
  margin: 0px;
  height: 100vh;
  width: 100vw;
  font-family: 'Avenir Next';
}

#root {
  height: 100vh;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
